<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Request RTS</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!--        <v-btn rounded color="primary" @click="dialog = false">-->
        <!--          Create-->
        <!--        </v-btn>-->
      </div>
      <v-card-text class="pt-6">
        <!--        entities form-->

        <form id="createForm" class="pa-3 pb-0">
          <v-text-field
            v-model="formData.comment"
            label="Comment"
            item-text="text"
            item-value="index"
            :items="agents"
            clearable
            required
            :error-messages="agent_idErrors"
            @input="$v.formData.comment.$touch()"
            @blur="$v.formData.comment.$touch()"
          ></v-text-field>
          <v-main class="text-center mt-4">
            <v-btn class="mr-4" color="primary" @click="submitRequestForm">
              Request
            </v-btn>
            <v-btn @click="resetCreateForm"> Clear </v-btn>
          </v-main>
        </form>
      </v-card-text>

      <div style="flex: 1 1 auto"></div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  SET_REPORT_MODAL_DATA,
  SET_REPORT_MODAL_STATE,
} from "@/core/services/store/config.module";
export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      comment: { required },
    },
  },
  name: "RequestRts",
  props: ["pageLoader", "agents", "updateTableContent"],
  data: () => ({
    dialog: false,
    dateFieldVisible: false,
    formData: {
      comment: null,
    },
    item: null,
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setData(itemData) {
      this.item = itemData;
    },
    toggleModal() {
      if (!this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    validateData() {
      return true;
    },
    submitRequestForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          let data = { order_ids: this.item, ...this.formData };
          ApiService.post("/customer_service/call_assignments/rts_req", data)
            .then((res) => {
              this.$store.commit(SET_REPORT_MODAL_DATA, res.data);
              this.$store.commit(SET_REPORT_MODAL_STATE, true);
              this.pageLoader(false);
              this.toggleModal();
              this.resetCreateForm();
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return null;
        }
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        comment: null,
      };
    },
  },
  computed: {
    agent_idErrors() {
      return this.handleFormValidation("comment", this);
    },
  },
};
</script>
